import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {CustomerCategoryModel} from '@customer/models';
import {CustomerMiscService} from '@customer/customer-misc.service';

@Component({
    selector: 'omt-confession-dialog',
    templateUrl: './customer-category-dialog.component.html',
    styleUrls: ['./customer-category-dialog.component.scss']
})
export class CustomerCategoryDialogComponent implements OnInit {
    saveEmitter = new EventEmitter<CustomerCategoryModel>();

    constructor(
        public dialogRef: MatDialogRef<CustomerCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public categoryModel: CustomerCategoryModel,
        private readonly customerMiscService: CustomerMiscService,
        private readonly translateService: TranslateService
    ) {
    }

    title: string;

    ngOnInit(): void {
        this.title = this.categoryModel.id == null ? this.translateService.instant('MANAGEMENT.NEW_CATEGORY') : this.translateService.instant('MANAGEMENT.EDIT_CATEGORY');
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    save(): void {
        if (this.categoryModel.id) {
            this.customerMiscService.updateCustomerCategory(this.categoryModel).subscribe(() => {
                this.saveEmitter.emit(this.categoryModel);
                this.closeModal();
            });
        } else {
            this.customerMiscService.createCustomerCategory(this.categoryModel).subscribe(() => {
                this.saveEmitter.emit(this.categoryModel);
                this.closeModal();
            });
        }
    }
}
